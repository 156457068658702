import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`A user with access to Engagement Studio.`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <p>{`A `}<strong parentName="p">{`landing page`}</strong>{`  is a targeted marketing effort that directs users to a specific page with a clear call-to-action (CTA) to achieve a goal, such as generating leads, driving sales, or promoting sign-ups. It is simply a web page that's been created with one purpose to convince visitors to take up an offerThe page is designed with minimal distractions and compelling content to maximize conversions. Common uses include product promotions, event registrations, and lead generation.`}</p>
    <h2>{`Steps`}</h2>
    <h3>{`1. Add campaign`}</h3>
    <ol>
      <li parentName="ol">{`On the side bar of Engagement Studio, click `}<em parentName="li">{`AdCenter`}</em>{` (`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "67px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.4776119402985%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAACc0lEQVQ4y41V2XaiQBDNB8w5zmgii4iC24hZVOK+4BKDsiXRZP7/T+6cKoGgiZqHC0139a2qvl3FlZjTcAqCXDwzV/x2z9WpjUmCG1FlCPJl51fnyCKUqwZKVQM/ySYmvBbyyGTz/E5ClIuwZgsGpXm8nskqHH1EyilnpQLuH0wsFitYsyWDxt3eCP3hBIPhBG2zh9HYQqc7xDxhN188oVa/Zw6OkNhLFQMvr2+8qdXuMtpml50MR1OsVjam1gLjiYVmq4OW2WXQuNcfwQ9eIef1fYQUtnHbxOrZRiotxmkQorS00l8U9Cr+XMvxfEb4tHFdn9cpSiasNx7wbK9xI+YhKXp80Dm1BFnR+YzUYhXGbQt6uR6nR6B11/OhapVPQiMkvBaU2JDmN47HKf/OSNg4Lm/0/YDTjISQctpXwnqCUAojonPdbndwXA+iXGDyUqWOXF5H8PLK62QnKz8gTGdzrJ7jeHjb7nD3YGL59IxytY5fqSzstQPjrsnX7CIhKVXQa9judphac9j2huF6AczHHvqDMTw/iFWVLhHSZKXWQPuxx6oKUgGd7gDDsYX1xsVsvkRRr8XCXDjDvcrkmRZpTKAxQ1TZZh+ZHqqs8bU5UrnJaZGaB2UlKBz1cbkdl53nBSho4T2kB334wQunM5nOMJnOw/cpzBlUOU8rG+u1w3V+UMt0JaicqMR2u3e02h2YnT7Mx0PQ2dKZfnz8Y4GoTNUwuoNuQxOpjIRytcFeo/TpahyChFM5KlI6fZOLW1ccYbInyvkSezzX++iqkE2yTON+uM9dO/AShX/cfI9tkt/7bh5G+N0/5GsnL551Eq3/B45MXNHI/hT6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/381f8/ec-adCenter.webp 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
                "alt": "icon-adCenter",
                "title": "icon-adCenter",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`).`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d97bc1c5e7e45691e309288d886aeeda/d0f99/ec-menu.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACe0lEQVQoz32QyWsTARTG5z8QVFwuKiq2dBFJBdGjqOCl3hREcVf8B8STUMW9HkTQghdXaOtS28nMtLbBYsFaWxubGmNrYpqETDJJprOkmWZp8pOmKnjxgx+893jv8fEJdTt2s+HiS7bdGKDpZh9bLktsbhGpvSJSd1Wk4Zqbhusi9ddFXLclXK0S2+9INLW62XVXYe+Dt+xr62fPPTc773QhrGvcxS3ZSyht8zVuEEhafP+DZhFM24xGMyj+GAFVxx/TCGqz9AbijM5oTMZ1ApqJTzU40T6EsGqLizdjQf6nChDVbaiUoVyASpGE7WBlc5QKhSrlUpEL0keENTVNdI5MVQ8LpQUWypV/WNRcvkh48SFQWhoR1i0iagJd19ENA9OyuCAPI6yucfHy03R1qbiwQPn3k6qzylLtFEtEZrOUi3mcuSyVYp64mcU0DBzLpJjLUcnPc2lgBGFtrYuWnhEmEyYjkTRedRbJH+V9UGU0msKr6nh+xOmbiqJZNtNahphhI09FaPs4waOxSR6P+3ni9XO2x4OwbH094pcQ0Xia+52DBEIqUTOH5cxjW1aVfKnE0E+N3m9J/vgfjiXY/7yb5nY3zR0iBzpEDr6SEFZu2srToUmeicMktRQPXwwyEUlhOw4z4TChUAgzm8UXzzAejP/NdTyhcei1zNFuhWM9CsfERWSE5Rsb6Rqbpve9j/4PX3n1doxwxmK+UEBPpchoGk6+QEw3sNPa33x9qTSHuyWOSwonFYVTvQqn+xSEFRsbafN4+RaM0OH5TCxt4tcMfDMqM2qSTHaORDbHeHSxT6BaNrM5h3fhCEd63JyUZc70yZzrlznvkfkF/n6e7vxCZiUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d97bc1c5e7e45691e309288d886aeeda/e93cc/ec-menu.webp 300w", "/static/d97bc1c5e7e45691e309288d886aeeda/b0544/ec-menu.webp 600w", "/static/d97bc1c5e7e45691e309288d886aeeda/68fc1/ec-menu.webp 1200w", "/static/d97bc1c5e7e45691e309288d886aeeda/a2303/ec-menu.webp 1800w", "/static/d97bc1c5e7e45691e309288d886aeeda/4293a/ec-menu.webp 2400w", "/static/d97bc1c5e7e45691e309288d886aeeda/81194/ec-menu.webp 3014w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d97bc1c5e7e45691e309288d886aeeda/eed55/ec-menu.png 300w", "/static/d97bc1c5e7e45691e309288d886aeeda/7491f/ec-menu.png 600w", "/static/d97bc1c5e7e45691e309288d886aeeda/8537d/ec-menu.png 1200w", "/static/d97bc1c5e7e45691e309288d886aeeda/d2cc9/ec-menu.png 1800w", "/static/d97bc1c5e7e45691e309288d886aeeda/00711/ec-menu.png 2400w", "/static/d97bc1c5e7e45691e309288d886aeeda/d0f99/ec-menu.png 3014w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d97bc1c5e7e45691e309288d886aeeda/8537d/ec-menu.png",
              "alt": "ec-menu",
              "title": "ec-menu",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
2. Click `}<em parentName="p">{`Campaigns`}</em>{` on the menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1142px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "140.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAYAAABh2p9gAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEkklEQVRIx5XU6VNTZxTH8audVm3dkF2BmJBAglaxiw61HWfU1mlRrI4O1g3BIkIIEdkklkIJBWuxikAFlTUs2ZO7JAS3mfqmf9a3c28wgnUUXpx5Xj2fOef5nXuFzU0S66+LrG8Q+eS6yMYGkaQmiaRGkc2OJ2T+1Ik5z4xl916Mpc2YLvdgKndiKu/CcMmJsfx15V92Iqy2BTG0z6Nrm8PQHiO5RUGo9iNU+xDsEYQjjVirrlK49xs2fHuFz6pvUVDxG3mXOiisdPJ5VRe7KjvZU9WFpaIjDqbeUEhpUbRzQ4OEYA2wyupHqI/yUXELJT8cxbzrS1KPWsm/3MX2823knG8jr7wDY1m7dporOtBf/BVBu1wbYHVtAEFFavwaKNT4EK4prDl6g6SNSaRs1ZNWYsNU4eSr2h6KrD0U1S6UtZui2m7223oQVtcGyWxVyPklSkqzjHDVF0dVsE5h/Yk2vjt0mH1fHyS5uIbu4SmUcBCf34/H7SYQ8CPLMrIkIUmSCgZIbpZJbpLY3CiR1iJrwWigPQ5a8szkWgrZ+qON/kkvc7JEKBwmFAoRDoc1SFoMblnANjWIpN9Q2NQoLoSisLakFZPewDa9WQP7JjzEIjJhUUwgoigmSgPTWhQyWhUN08at9ic6XFviQJ+jI8tgIeuEjfuTHmJKHHyFvEKXjKzu3ge2oBZQIhS7wsfHb5Kfa0Rn2kH2yaXg4lFfj6yuTYvClib5dcKJlCOsK3Gwq6AAff6nGqi94btAtatXKWtvt3ht7IoGGnTbyc61oDtVR/+k592gOqKa6hp7aGEXl4687riDPEN8ZA10LaPDrJsRPqwL/n9kNZRjrRqYYyxg++k67Q2jikwoLC5JN5Fy/PIi6M0OSxwUqHto3q2BQzM+ns1FiMiyBr9ZglAb5K2lfobXoqw51oouK5tt+nx0p204BsZxeXyMzHgYnfUuqbFZ70KHby1/IuWdZgumgkINPNB4mzJnHxc6+7jojNeFzntcdN6jrKvvfR1GtMXemp5JRo6J3NI67ozNIIcCeL1evB4vPq+XWFTh6fyc9j0vC8xIzSA924jprJ3BKTVliWAoxHwsij8Q5NbdIe70P4qnvBwwMy3eoQoOTHmIyBKxuSjtt/rR7z+L5WAZeQcuUHCobPlgeraJvLN2+ifdvHgyx1+DI6QUnqSpvVf76/h8Aa40OFcG5qugy80/z+c5fMbOqcpWomKAkcePGR8bxeueWcHIKnjOzoDLw4v5OfZ8/zMO512iioTL5WJmehqfz79yUO3w5fN5jpyr51hZI/++fEEoGEQWw1rSKwLNCx0+i0UZfDhOyp6T2rsFAkFm3T5KqxzvX+zFKavg39Pxv83TJ/P0Djxmx+FycorOkLWvlC+KK98B1rwZipGdZfV0PxhnaGiE4eEhXBNjjI2OMPRogtGJGWLRyMr20HzeTufDKUYnZxl4MMTg8EOmZt2IsoQoy/iCIbVDP8JVN0LVLELVTKJWXZlGsAZZV9xERmo6Gdm5GE5XUdzyO829/TT0DtB4Z4Drf97n2h/3qFfrdh//AZ3y+NrIMEgYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9d58220d59f992bf224028c42f529ca9/e93cc/ec-adCenter-campaign.webp 300w", "/static/9d58220d59f992bf224028c42f529ca9/b0544/ec-adCenter-campaign.webp 600w", "/static/9d58220d59f992bf224028c42f529ca9/7c99d/ec-adCenter-campaign.webp 1142w"],
              "sizes": "(max-width: 1142px) 100vw, 1142px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9d58220d59f992bf224028c42f529ca9/eed55/ec-adCenter-campaign.png 300w", "/static/9d58220d59f992bf224028c42f529ca9/7491f/ec-adCenter-campaign.png 600w", "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png 1142w"],
              "sizes": "(max-width: 1142px) 100vw, 1142px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png",
              "alt": "ec-adCenter-campaign",
              "title": "ec-adCenter-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
3. Click `}<em parentName="p">{`Add`}</em>{` to open a new Campaign.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9e640e031871779b1f6dd619d333233f/bb056/ec-add-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACS0lEQVQoz4XR/UtTURzH8fsfRCgVKj1rkoIapgkRkihYQRAEQQRBIBb1oz2g9OAPItkDVNSydG5T0PSuvE7cZv1gqTnnSgnWNTcL+ik15ja33T3cvcOraEHRB158z4Fzzg+fI+SUlLO5pof0un7SayU21IhsvCySelVk0zWRLbUiaXUiGddFtt4ws+2WmR31ZnbWm8lu6KWgqY/Ce/3k3+6luLEbIW1vMdWmEUyOWXRDMs3DX2h570E/5qVtzIvB4cUw7sW4xoPJ6aFjwkv7+AydLi9dH79invrGJXEUITWzgG7HDCtR+W8SsbVlXAkTCYchmdT2XZNehIzcA4jO1QeTidWjyX9LxLWpxhPaTESjxBRFu9Uz5UHI2l9KVfs7dCMzPBxy82RYRjci83RU5tl7medjMi0OmdbxafTOZTIG17TG6JIxfZimzfUZvdPNFesowvbcIrJudpPdIJHT2Etek8S+uxKF9yWKHkiUPJI4+FjikE6itLmPshYL5a0WKvR9VBr7OWKyaI62rxBSM/MxjLgJRRTm/Ev4QhGNPxIloMTWBKMx/BGFhcASi+EIfiXKD98i8/4AixGFeELFOOlGSNmVh3litUM18Vtff48aj4OqoiZUrb9QSCEaXfkoq2cWIWV3Hq1vPzHv8/N9wcdcIPSH+eC6hWVL636Gwpq5YJBgKESX242QtqeA4juvOKwbpEw3QEXzAJUtVo61WTlutHGiw8bJThunXtg4Ldo489LOWcnOOYudqgE71TY7FwbtXHxj5/xrG78A0wOGSy6/ESYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e640e031871779b1f6dd619d333233f/e93cc/ec-add-campaign.webp 300w", "/static/9e640e031871779b1f6dd619d333233f/b0544/ec-add-campaign.webp 600w", "/static/9e640e031871779b1f6dd619d333233f/68fc1/ec-add-campaign.webp 1200w", "/static/9e640e031871779b1f6dd619d333233f/a2303/ec-add-campaign.webp 1800w", "/static/9e640e031871779b1f6dd619d333233f/4293a/ec-add-campaign.webp 2400w", "/static/9e640e031871779b1f6dd619d333233f/cb4c3/ec-add-campaign.webp 2962w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e640e031871779b1f6dd619d333233f/eed55/ec-add-campaign.png 300w", "/static/9e640e031871779b1f6dd619d333233f/7491f/ec-add-campaign.png 600w", "/static/9e640e031871779b1f6dd619d333233f/8537d/ec-add-campaign.png 1200w", "/static/9e640e031871779b1f6dd619d333233f/d2cc9/ec-add-campaign.png 1800w", "/static/9e640e031871779b1f6dd619d333233f/00711/ec-add-campaign.png 2400w", "/static/9e640e031871779b1f6dd619d333233f/bb056/ec-add-campaign.png 2962w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9e640e031871779b1f6dd619d333233f/8537d/ec-add-campaign.png",
              "alt": "ec-add-campaign",
              "title": "ec-add-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
4. Change `}<em parentName="p">{`Type`}</em>{` to `}<em parentName="p">{`Landing Page`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3edc098ea812c023977de885510a2d33/f9aab/ec-new-campaign-landing-page.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABg0lEQVQ4y51TTUvDQBDN3/ZHePAXCB68eyt4aAUVwYOgUNqkNv0wVtK0ST/T7Gbz5K2dGKtI6MAws5udt2/ebBx/OELX68HtvdrY8wfoD0foD2q6PTvEYDRG6/YejlYKq9UKm80GSRIjz3Mca2E4hXO4WRQFlNbQWlvw3BgbudaM+5x7xpjSaUEQfAFyg0B0GhnTsyzDdru1Ue8vEbA0TbHb7UoStMlk8jcgi1hAGeI4toX8JmyY85JagMyVUhaUBbbtSpsSCUiX+n8Zsl0yXC6XFtTsdaxG7tdiyAIyLAchw6k4z9diSCNYkiTlQLgWKao6kh27qDIM3t9/MyQj0ZAFBBWnFCKBgMgltDd5NlX9ONkwDLFery3T2WyGKIqsnovFwgKzbUZeaApA5QaEHAug6CLPYT6fWyYsIijByPh7KDlUxqGkP36KkuExlmQFNIBGJ8Jps4/Lpw+E0wiO7/twXRee55VeXTM//N7udPH43MZLu4PzxgNOLq5xdnWHZusGnwVlOm0wBOUHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3edc098ea812c023977de885510a2d33/e93cc/ec-new-campaign-landing-page.webp 300w", "/static/3edc098ea812c023977de885510a2d33/b0544/ec-new-campaign-landing-page.webp 600w", "/static/3edc098ea812c023977de885510a2d33/68fc1/ec-new-campaign-landing-page.webp 1200w", "/static/3edc098ea812c023977de885510a2d33/81358/ec-new-campaign-landing-page.webp 1438w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3edc098ea812c023977de885510a2d33/eed55/ec-new-campaign-landing-page.png 300w", "/static/3edc098ea812c023977de885510a2d33/7491f/ec-new-campaign-landing-page.png 600w", "/static/3edc098ea812c023977de885510a2d33/8537d/ec-new-campaign-landing-page.png 1200w", "/static/3edc098ea812c023977de885510a2d33/f9aab/ec-new-campaign-landing-page.png 1438w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3edc098ea812c023977de885510a2d33/8537d/ec-new-campaign-landing-page.png",
              "alt": "ec-new-campaign-landing-page",
              "title": "ec-new-campaign-landing-page",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{}}>{`* _Name_: It is required  and it is free text but you should give a meaningful name.
* _Type_: Landing Page
* _Channel_: Select _Default_ or create a new one to add.
* _Contact list_: Select an existing contact list or create a new one or _-none-_.
* _Unique label_: System generated
* _Placeholder_: Select _-new placeholder-_, if you want to create a new one or re-use an existing. 
`}</code></pre>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Fill in the name.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b550e53d851ac0a0a6292655bb2c2dd/b62b2/ec-new-campaign-landing-filled-in.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABq0lEQVQ4y6WS20ojQRCG87hee+Mb6IVoiLgmEVxYEJ9hxfUUZUW88UZ2rxYluozJMId0p889/1I9mdkxEVQs+KmePnz8VVOtwc9rHJ6c4+h0gKOzC/wgnV++S8cz0bvB1TWWllfQAgApRBBnDJ+J9a2dEmiMgXMO1to6K2PBpQ4S2kJqB9GUcTDWw3sPY2wA7nS7JZA2m8EYQ5IzDKMY908RUq7AlQNTNojWtCeNC/fJBEW/3y+BtFEURRCF1hpSSjDGkWYZpNKwzsNYF0RrxqehsjeBpKp0euC9CxXQt3euzN7BaF2/e9PhZDIJDikrpWugq4EeQogFh71ebxE4/3OaoKb0Kw53d+ccVv3Lsizk6XRan5GrKpPobN5he/tLCbQNh83yCEpSSgVRG6oRe9HzGXB1s7PokHOOKIpCjuMYo9EI4/E4uE6SJED/99DCFwX0bA5X268AyUme5/Xo0JrgVcnhrvdQUsBb+2J+N7a2S+BHQ9kCqbDIlcP+zRBrh79x8cDw7eseWmSdSqNy3qM0TRCNY/x5fMb93xEOLn+h8/0Wx3dDdNqb+AcODynZrAfy2QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b550e53d851ac0a0a6292655bb2c2dd/e93cc/ec-new-campaign-landing-filled-in.webp 300w", "/static/2b550e53d851ac0a0a6292655bb2c2dd/b0544/ec-new-campaign-landing-filled-in.webp 600w", "/static/2b550e53d851ac0a0a6292655bb2c2dd/68fc1/ec-new-campaign-landing-filled-in.webp 1200w", "/static/2b550e53d851ac0a0a6292655bb2c2dd/f6dd4/ec-new-campaign-landing-filled-in.webp 1454w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b550e53d851ac0a0a6292655bb2c2dd/eed55/ec-new-campaign-landing-filled-in.png 300w", "/static/2b550e53d851ac0a0a6292655bb2c2dd/7491f/ec-new-campaign-landing-filled-in.png 600w", "/static/2b550e53d851ac0a0a6292655bb2c2dd/8537d/ec-new-campaign-landing-filled-in.png 1200w", "/static/2b550e53d851ac0a0a6292655bb2c2dd/b62b2/ec-new-campaign-landing-filled-in.png 1454w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2b550e53d851ac0a0a6292655bb2c2dd/8537d/ec-new-campaign-landing-filled-in.png",
              "alt": "ec-new-campaign-landing-filled-in",
              "title": "ec-new-campaign-landing-filled-in",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
5. On the right bottom corner of form `}<em parentName="p">{`New campaign`}</em>{`, click button `}<em parentName="p">{`Accept`}</em>{`.`}</p>
    <h4>{`Result`}</h4>
    <p>{`After accepting, `}<em parentName="p">{`Edit Landing Page`}</em>{` is open.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/66f44aabef45c463b5664d0e6134238a/ea446/ec-edit-landing-page.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABf0lEQVQoz4WRy2oUURBA+5NEgviMLly4EzF/5MZnfIWQhYir/EA2PZgoouAnBFSIcYZMMJl093Tu+3Hk3kkbHWdMwaG7urpOVfctLj3aYvHpO64ub3Hufsn5ByULD0suPC65+KTk8nLJlWcl1573WHzR4/rLHjdWetxcfcuttU3uvH7P0psP3H21ye3VDYqPX4b8OKj5ftCwV0v2apEZNnIu/VHD4GjMdn/IzmDI+uev3Cu3Wfv0jWK/kcwKZzRWKyAyP+JftzFGit3Rcc59iIR4ipASZWxuyc/CDE7eTaK8RAgUOz9rjJJIKQkhTOTeo43BGIu1E1JtHvFE6pOwX002/HNSErRtm1FK5TwNmcY5l2v5izvhYErYobXODWmDWbKObkOmhXFqw6qqEEJgjMniWXS/oot/N0zXEJBC0LbHGOfzkHQAMf6f38Ldo9QY0C6grKdRlkrYnCdSTVqP9YGzwsckPKyJ3mGsxTmLVJpRVTMejxFK4+zpSZ9FcvwCZpBBZ6UcSbQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/66f44aabef45c463b5664d0e6134238a/e93cc/ec-edit-landing-page.webp 300w", "/static/66f44aabef45c463b5664d0e6134238a/b0544/ec-edit-landing-page.webp 600w", "/static/66f44aabef45c463b5664d0e6134238a/68fc1/ec-edit-landing-page.webp 1200w", "/static/66f44aabef45c463b5664d0e6134238a/a2303/ec-edit-landing-page.webp 1800w", "/static/66f44aabef45c463b5664d0e6134238a/4293a/ec-edit-landing-page.webp 2400w", "/static/66f44aabef45c463b5664d0e6134238a/3123a/ec-edit-landing-page.webp 2788w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/66f44aabef45c463b5664d0e6134238a/eed55/ec-edit-landing-page.png 300w", "/static/66f44aabef45c463b5664d0e6134238a/7491f/ec-edit-landing-page.png 600w", "/static/66f44aabef45c463b5664d0e6134238a/8537d/ec-edit-landing-page.png 1200w", "/static/66f44aabef45c463b5664d0e6134238a/d2cc9/ec-edit-landing-page.png 1800w", "/static/66f44aabef45c463b5664d0e6134238a/00711/ec-edit-landing-page.png 2400w", "/static/66f44aabef45c463b5664d0e6134238a/ea446/ec-edit-landing-page.png 2788w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/66f44aabef45c463b5664d0e6134238a/8537d/ec-edit-landing-page.png",
              "alt": "ec-edit-landing-page",
              "title": "ec-edit-landing-page",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3>{`2. Edit Landing Page`}</h3>
    <ol>
      <li parentName="ol">{`Activate the Landing Page by changing the `}<em parentName="li">{`Status`}</em>{` toggle.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1110fd7064f53376e45c342d7120d748/7e1c9/ec-landing-status.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "14.666666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAcElEQVQI122OQQrDMAwE/f9jf5Cf5NhTHxAo9JJragx2bEurLSqBBJOBQQgWaUOtla6q0jGzQxDAZT9trbH3zu0bmePG17LyMb85PT8MvOBhR0SYUmIp5X901HM+7wjjdweqzHulwobW9wJGUVAA/gBJgut5ZDlWtgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1110fd7064f53376e45c342d7120d748/e93cc/ec-landing-status.webp 300w", "/static/1110fd7064f53376e45c342d7120d748/b0544/ec-landing-status.webp 600w", "/static/1110fd7064f53376e45c342d7120d748/68fc1/ec-landing-status.webp 1200w", "/static/1110fd7064f53376e45c342d7120d748/a2303/ec-landing-status.webp 1800w", "/static/1110fd7064f53376e45c342d7120d748/4293a/ec-landing-status.webp 2400w", "/static/1110fd7064f53376e45c342d7120d748/417e5/ec-landing-status.webp 2698w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1110fd7064f53376e45c342d7120d748/eed55/ec-landing-status.png 300w", "/static/1110fd7064f53376e45c342d7120d748/7491f/ec-landing-status.png 600w", "/static/1110fd7064f53376e45c342d7120d748/8537d/ec-landing-status.png 1200w", "/static/1110fd7064f53376e45c342d7120d748/d2cc9/ec-landing-status.png 1800w", "/static/1110fd7064f53376e45c342d7120d748/00711/ec-landing-status.png 2400w", "/static/1110fd7064f53376e45c342d7120d748/7e1c9/ec-landing-status.png 2698w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1110fd7064f53376e45c342d7120d748/8537d/ec-landing-status.png",
              "alt": "ec-landing-status",
              "title": "ec-landing-status",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
2. In section `}<em parentName="p">{`Page parameters configuration`}</em>{`, change one or more of the following fields, according to your needs:`}</p>
    <pre><code parentName="pre" {...{}}>{`* _Name_: It is required  and it is free text but you should give a meaningful name.
* _Unique label_: System generated
* _Placeholder_: Select _-new placeholder-_, if you want to create a new one or re-use an existing. 
* _Title_: It is free text.
* _Description_: It is free text.
* _Index rules_: Select_index_ or _nofollow_
* _Keywords_: It is free text.
* _Advanced configuration_: Toggle Active or Inactive
* _Content to insert in HEAD tag_: If Advanced configuration active and it is free text.
* _Content to insert before page elements_: If Advanced configuration active and it is free text.
* _Content to insert after page elements_: If Advanced configuration active and it is free text.
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Clicking the chain next to the ID, the internal landing url and the inline script to add this landings contents to a page are available.
3. On the right top corner of form `}<em parentName="p">{`Edit Landing Page`}</em>{`, click button `}<em parentName="p">{`Save campaign`}</em>{`.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cdb594b88d2e852d49e4fcab4a6bc134/514cb/ec-edit-landing.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA8ElEQVQoz4WRS27DMAxEffwcoducI8uihyi86CZtjNqNnOhD6jsFFdtwYQclMKAgkk8jqfn4HvE53HAZLXrN6DXVPJiHekMYFnHNF3VHN2q05w7nrw7HtxaH0zteXls0pRRso4DJIXiu66cq6wwox2g4ROSckXKGwKWWUoI2FiGmB74U5B3N/bOpH0tojCOQc2Dvp4aCGCN8CPDeV4UQ6iF7ypMRiasAQ8qLi7lARNBawxgDZt4FCkj25fCyXLkC0wYoAwKSofocT9yJ1nMTcOvQOQelVM2ziz1JbX3lkfgvUEIajdHQ1iIuH/W/KpAZv54+ctQki0jDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cdb594b88d2e852d49e4fcab4a6bc134/e93cc/ec-edit-landing.webp 300w", "/static/cdb594b88d2e852d49e4fcab4a6bc134/b0544/ec-edit-landing.webp 600w", "/static/cdb594b88d2e852d49e4fcab4a6bc134/68fc1/ec-edit-landing.webp 1200w", "/static/cdb594b88d2e852d49e4fcab4a6bc134/a2303/ec-edit-landing.webp 1800w", "/static/cdb594b88d2e852d49e4fcab4a6bc134/4293a/ec-edit-landing.webp 2400w", "/static/cdb594b88d2e852d49e4fcab4a6bc134/f9ef4/ec-edit-landing.webp 2754w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cdb594b88d2e852d49e4fcab4a6bc134/eed55/ec-edit-landing.png 300w", "/static/cdb594b88d2e852d49e4fcab4a6bc134/7491f/ec-edit-landing.png 600w", "/static/cdb594b88d2e852d49e4fcab4a6bc134/8537d/ec-edit-landing.png 1200w", "/static/cdb594b88d2e852d49e4fcab4a6bc134/d2cc9/ec-edit-landing.png 1800w", "/static/cdb594b88d2e852d49e4fcab4a6bc134/00711/ec-edit-landing.png 2400w", "/static/cdb594b88d2e852d49e4fcab4a6bc134/514cb/ec-edit-landing.png 2754w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/cdb594b88d2e852d49e4fcab4a6bc134/8537d/ec-edit-landing.png",
              "alt": "ec-edit-landing",
              "title": "ec-edit-landing",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
4. After saving, Landing Page campaign is saved.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The landing page campaigns have the same tab structure as the onsite campaigns. `}</p>
    </blockquote>
    <h4>{`Result`}</h4>
    <p>{`After activating, Landing Page campaign is active.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/03424eed726a0729354ac35f66f7ef39/ef928/ec-ad-center-landing.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACGUlEQVQoz43R3UtTcRzH8fMHREREGFJBFIEEIYhERPRAdFH/RfcRlBQmPZCKT8uWmelSs243OzuOgtTUpfOhpBL3m0GkBbbKefScs+N2HuY7tiUpeOEXXnx/v9/n7veRCsp7KKzoYfu1brZd9bOjzM/O63523fCzu9xPwU0/eyoCFN4KsPd2gH13Auy/G+Bg5UuKaoKUeEIUN4QorvZTWteN5OmNUvVqiurXU9T2RqnvEzT0CzxvBfcHBI0DggeDa6J4hwRN4RhNYUFzWPAkMkNr5AvewWku+t4gkZtVtjaZ/8dVF8tMbkivBEeRMm6GlGWz6rpkXIeM4+BuIpsZSRPXtllKpkinLTQ9mWeYOLZNWWgUaV41mImrfFvQcmYTWTpzCY25xbzv66n/3lSdH6qWMxNPEJ1f4HLPMNKBezKHqmQOV8sU1cocqZc56pEpbpQp8cqUNskcaw5yvCXIiVaFk21BTvkUTrcrnOlQONupcO6ZwvkuhQsvQkgP+qeo6/uMdyjKw3CUR8OCxyOCloigdUzQNi7wTcTwTQieTgjaPwg6J2N0TGZ3lK6PMZ5/iuF7P82lYN9aKVuddeVlXNKmuSGtfDeOZDsuacvKfarjOLltbyJbzJJmsGKaqEaKlZSVu+foSay0RU1kDCmhm8wllplfMvKWDX5maXnxNXqSuKbn6Qa/DIPfhsEfw2A2ofJ1YZHK0RH+Au4QqabjCK5nAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/03424eed726a0729354ac35f66f7ef39/e93cc/ec-ad-center-landing.webp 300w", "/static/03424eed726a0729354ac35f66f7ef39/b0544/ec-ad-center-landing.webp 600w", "/static/03424eed726a0729354ac35f66f7ef39/68fc1/ec-ad-center-landing.webp 1200w", "/static/03424eed726a0729354ac35f66f7ef39/a2303/ec-ad-center-landing.webp 1800w", "/static/03424eed726a0729354ac35f66f7ef39/4293a/ec-ad-center-landing.webp 2400w", "/static/03424eed726a0729354ac35f66f7ef39/a853d/ec-ad-center-landing.webp 2838w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/03424eed726a0729354ac35f66f7ef39/eed55/ec-ad-center-landing.png 300w", "/static/03424eed726a0729354ac35f66f7ef39/7491f/ec-ad-center-landing.png 600w", "/static/03424eed726a0729354ac35f66f7ef39/8537d/ec-ad-center-landing.png 1200w", "/static/03424eed726a0729354ac35f66f7ef39/d2cc9/ec-ad-center-landing.png 1800w", "/static/03424eed726a0729354ac35f66f7ef39/00711/ec-ad-center-landing.png 2400w", "/static/03424eed726a0729354ac35f66f7ef39/ef928/ec-ad-center-landing.png 2838w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/03424eed726a0729354ac35f66f7ef39/8537d/ec-ad-center-landing.png",
              "alt": "ec-ad-center-landing",
              "title": "ec-ad-center-landing",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      